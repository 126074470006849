<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="门店" prop="storeId" required>
        <el-select v-model="form.storeId" placeholder="请选择门店">
          <el-option
            v-for="item in store()"
            :key="item.id"
            :label="item.storeName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色编码" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入角色编码" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="角色名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入角色名称" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="角色级别" prop="level" required>
        <el-select v-model="form.level" placeholder="请选择">
          <el-option
            v-for="item in 20"
            :key="item"
            :label="item + '级'"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="任务发布权限" prop="canPublish" class="form-item-switch">
        <el-switch v-model="form.canPublish"/>
        <!-- <FormItemTip>是否有任务发布权限</FormItemTip> -->
      </el-form-item>
      <el-form-item label="角色备注" prop="remark">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入角色备注" :rows="3" v-trim maxlength="500"/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 原角色码
      originRoleCode: '',
      // 表单数据
      form: {
        storeId: '',
        id: null,
        code: '',
        name: '',
        canPublish: 1,
        remark: '',
        level: ''
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入角色编码' }
        ],
        name: [
          { required: true, message: '请输入角色名称' }
        ],
        level: [
          { required: true, message: '请输选择角色级别' }
        ],
        storeId: [
          { required: true, message: '请选择门店' }
        ]
      },
    }
  },
  inject: ['store'],
  methods: {
    /**
     * 打开窗口
     *
     * @param title 窗口标题
     * @param target 行对象（仅编辑需该参数）
     */
    open (title, target) {
      this.title = title
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        this.originRoleCode = target.code
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        console.log('this.form', this.form)
      })
    },
    /**
     * 确认
     */
    confirm () {
      if (this.form.id == null || this.form.id === '') {
        this.__confirmCreate()
        return
      }
      if (this.originRoleCode === this.form.code) {
        this.__confirmEdit()
        return
      }
      // 修改了角色编码
      this.$dialog.confirm('检测到您修改了角色编码，角色编码修改后前后端均可能需要调整代码，确认修改吗？', '提示', {
        confirmButtonText: '确认修改',
        type: 'warning'
      })
        .then(() => {
          this.__confirmEdit()
        })
    }
  },
  created () {
    this.config({
      api: '/system/role'
    })
  }
}
</script>
