<template>
    <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="门店" prop="storeId">
          <el-select v-model="form.storeId" @change="changeStore($event)" placeholder="请选择门店">
            <el-option
              v-for="item in store()"
              :key="item.id"
              :label="item.storeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="授权角色" prop="roleId">
          <el-select v-model="form.roleId" placeholder="请选择角色(默认授权所有)" multiple clearable>
            <el-option
              v-for="item in role"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入任务标题" v-trim/>
        </el-form-item>
        <el-form-item label="任务内容" prop="content">
          <el-input v-model="form.content" :rows="5" type="textarea" placeholder="请输入任务内容" v-trim/>
        </el-form-item>
      </el-form>
    </GlobalWindow>
  </template>
  
  <script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  import { fetchAll as fetchRoleList } from '@/api/system/role.js'
  export default {
    name: 'OperaTaskTemplateWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        role: [],
        // 表单数据
        form: {
          id: null,
          title: '',
          content: '',
          storeId: '',
          roleIds: '',
          roleId: []
        },
        // 验证规则
        rules: {
            title: [
              { required: true, message: '请输入任务标题' }
            ],
            content: [
              { required: true, message: '请输入任务内容' }
            ],
            storeId: [
              { required: true, message: '请选择门店名称' }
            ]
        }
      }
    },
    inject: ['store'],
    created () {
      this.config({
        api: '/ext/taskTemplate',
        'field.id': 'id'
      })
    },
    methods: {
      onOpen() {
        console.log('storeId', this.form.storeId)
        if(this.form.storeId) {
          this.getRoleList(this.form.storeId)
          let roleIds = []
          if(this.form.roleIds) {
            let roleIdsStr = this.form.roleIds.split(',')
            roleIdsStr.forEach(element => {
              roleIds.push(parseInt(element))
            });
            this.form.roleId = roleIds
          }
        }
      },
      getRoleList(storeId) {
        fetchRoleList({storeId:storeId})
            .then(res => {
              let list = []
              res.forEach(element => {
                if(element.canPublish) list.push(element)
              });
              this.role = list
            })
      },
      changeStore (e) {
        console.log('changeStore', e)
        this.getRoleList(e)
      }
    },
  }
  </script>
  