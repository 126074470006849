<template>
  <GlobalWindow
    :visible.sync="visible"
    :confirm-working="isWorking"
    title="配置用户角色"
    @confirm="confirm"
  >
    <p class="tip" v-if="user != null">为用户 <em>{{user.realname}}</em> 配置角色</p>
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="门店" prop="store" required v-if="$store.state.userInfo.roles[0] != 'admin'">
        <el-select v-model="form.store" @change="changeStore()" placeholder="请选择门店">
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.storeName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="role" required>
        <el-select v-model="form.role" @change="changeRole" placeholder="请选择角色">
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位" prop="post" required v-if="$store.state.userInfo.roles[0] != 'admin' && form.role !== 101">
        <el-select v-model="form.post" placeholder="请选择岗位">
          <el-option
            v-for="item in options3"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" prop="dept" v-if="$store.state.userInfo.roles[0] != 'admin' && form.role !== 101">
        <el-select v-model="form.dept" clearable placeholder="请选择部门">
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BasePage from '@/components/base/BasePage'
import GlobalWindow from '@/components/common/GlobalWindow'
import { createUserRole } from '@/api/system/user'
import { fetchAll } from '@/api/system/role'
import { fetchListAll } from '@/api/ext/store'
import { findList } from '@/api/system/position'
import * as department from '@/api/system/department'
import PositionSelect from '@/components/system/position/PositionSelect'
export default {
  name: 'RoleConfigWindow',
  extends: BasePage,
  components: { GlobalWindow, PositionSelect },
  data () {
    return {
      visible: false,
      isWorking: false,
      // 用户
      user: null,
      code: '',
      // 角色列表
      // roles: null,
      // 已选中的角色ID
      // selectedIds: []
      options1: [],
      options2: [],
      options3: [],
      departmentList: [],
      form: {
        role: null,
        store: null,
        post: null,
        dept: null
      },
      // 验证规则
      rules: {
        role: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        store: [
          { required: true, message: '请选择门店', trigger: 'change' }
        ],
        post: [
          { required: true, message: '请选择岗位', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    visible (newValue, oldValue) {
      if (!newValue) {
        this.code = null
        this.form = {
          role: null,
          store: null,
          post: null,
          dept: null
        }
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    changeRole (e) {
      if (e === 101) {
        this.form.post = ''
      }
    },
    /**
     * 打开窗口
     *
     * @param user 目标用户
     */
    open (user) {
      this.options1 = []
      this.options2 = []
      this.options3 = []
      this.departmentList = []
      this.user = user
      if (this.$store.state.userInfo.roles[0] != 'admin') {
        // 查询所有门店
        fetchListAll()
          .then(res => {
            this.options2 = res
          })
        this.form.role = user.roles.length > 0 ? user.roles[0].id : ''
        this.form.post = user.positions.length > 0 ? user.positions[0].id : ''
        this.form.store = user.storeId ? user.storeId : ''
        this.form.dept = user.departmentId ? user.departmentId : ''
        this.getRole()
      } else {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
        })
        this.form.role = ''
        this.form.post = ''
        this.form.store = ''
        this.getRole()
      }
      this.visible = true
    },
    changeStore () {
      this.form.role = ''
      this.form.post = ''
      this.getRole()
    },
    getRole () {
      // 查询所有角色
      fetchAll({ storeId: this.form.store })
        .then(records => {
          this.options1 = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
      findList({ storeId: this.form.store })
        .then(res => {
          this.options3 = res
        })
      department.findList({ storeId: this.form.store }).then(res => {
        this.departmentList = res
      })
    },
    // 查询所有岗位
    // getGW() {
    //   findList({ storeId: this.form.store })
    //     .then(res => {
    //       this.options3 = res
    //     })
    // },
    /**
     * 确认选择角色
     */
    confirm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isWorking = true
          createUserRole({
            userId: this.user.id,
            roleIds: this.form.role ? [this.form.role] : [],
            storeId: this.form.store,
            departmentIds: this.form.dept ? [this.form.dept] : [],
            positionIds: this.form.post ? [this.form.post] : []
          })
            .then(() => {
              this.visible = false
              this.$emit('success')
              setTimeout(() => {
                this.$dialog.attentionConfirm('角色配置成功，用户需重新登录后生效').then(() => {})
              }, 300)
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        } else {
          return false
        }
      })
    },
    // 关闭
    close () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
// 角色配置
.global-window {
  .tip {
    em {
      font-style: normal;
      color: $primary-color;
      font-weight: bold;
    }
  }
}
</style>
